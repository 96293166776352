import React, { useState } from 'react'
import './Header.css'

const Header = () => {
  // CHANGE BACKGROUND HEADER
  window.addEventListener('scroll', function () {
    const header = document.querySelector('.header')
    if (this.scrollY >= 80) header.classList.add('scroll-header')
    else header.classList.remove('scroll-header')
  })
  // TOGGLE MENU
  const [Toggle, showMenu] = useState(false)

  return (
    <header className='header'>
      <meta
        name='keywords'
        content='kemal, kemalcan, kemal can, kemal can sarı, can sarı, cansarı'
      />
      <meta name='author' content='Kemal Can SARI'></meta>
      <nav className='nav container'>
        <a href='/' className='nav__logo'>
          Kemal Can SARI
        </a>

        <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
          <ul className='nav__list grid'>
            {/* <li className='nav__item'>
              <i
                className='uil uil-moon change-theme nav__link'
                id='theme-buton'
              ></i>
            </li> */}

            <li className='nav__item'>
              <a href='#about' className='nav__link'>
                <i className='uil uil-user nav__icon'> About</i>
              </a>
            </li>

            <li className='nav__item'>
              <a href='#projects' className='nav__link'>
                <i className='uil uil-file-alt nav__icon'> Projects</i>
              </a>
            </li>

            {/* <li className='nav__item'>
              <a href='#porfolio' className='nav__link'>
                <i className='uil uil-scenery nav__icon'> Portfolio</i>
              </a>
            </li> */}

            <li className='nav__item'>
              <a href='#contact' className='nav__link'>
                <i className='uil uil-message nav__icon'> Contact</i>
              </a>
            </li>
          </ul>

          <i
            className='uil uil-times nav__close'
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>

        <div className='nav__toggle' onClick={() => showMenu(!Toggle)}>
          <i className='uil uil-apps'></i>
        </div>
      </nav>
    </header>
  )
}

export default Header
