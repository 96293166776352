import './App.css'
import Header from './Components/Header'
import Home from './Components/Home'
import Contact from './Components/Contact'
import Footer from './Components/Footer'
import { ScrollUp } from './Components/ScrollUp'
import Projects from './Components/Projects.js'

function App() {
  return (
    <>
      <Header />
      <main className='main'>
        <Home />
        <Projects />
        <Contact />
      </main>
      <Footer />
      <ScrollUp />
    </>
  )
}

export default App
