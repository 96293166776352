import React from 'react'
import './ScrollUp.css'

export const ScrollUp = () => {
  window.addEventListener('scroll', function () {
    const scrollUp = document.querySelector('.scrollup')
    if (this.scrollY >= 460) scrollUp.classList.add('show-scroll')
    else scrollUp.classList.remove('show-scroll')
  })

  return (
    <a href='#about' className='scrollup'>
      <i className='uil uil-arrow-up scrollup__icon'></i>
    </a>
  )
}
