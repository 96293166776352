import React from 'react'
import './Projects.css'
import { projectsData } from '../Data/projectsData'

const Projects = () => {
  return (
    <section className='work section' id='projects'>
      <h2 className='section__title'>Projects</h2>
      <span className='section__subtitle'>Most recent projects</span>
      <div>
        {/* <div className='work__filters'>
          {projectsNav.map((item, index) => {
            return (
              <span className='work__item' key={index}>
                {item.name}
              </span>
            )
          })}
        </div> */}

        <div className='work__container container grid'>
          {projectsData.map((item, index) => {
            return (
              <>
                <div className='work__card' key={index}>
                  <a href={item.link} target='_blank' rel='noopener noreferrer'>
                    <img
                      src={item.image}
                      alt={item.title}
                      className='work__img'
                    />
                  </a>

                  <h3 className='work__title'>{item.title}</h3>
                  <a
                    href={item.source}
                    className='work__button'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Source code
                    <i className='bx bx-right-arrow-alt work__button-icon' />
                  </a>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Projects
