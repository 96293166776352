import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <h1 className='footer__title'>Kemal Can SARI</h1>

        <ul className='footer__list'>
          <li>
            <a href='#about' className='footer__link'>
              About
            </a>
          </li>

          <li>
            <a href='#projects' className='footer__link'>
              Projects
            </a>
          </li>

          <li>
            <a href='#contact' className='footer__link'>
              Contact
            </a>
          </li>
        </ul>

        <div className='footer__social'>
          <a
            href='https://github.com/kcansari'
            className='footer__social-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='uil uil-github-alt'></i>
          </a>

          <a
            href='https://www.linkedin.com/in/kemal-can-sari-5033421b0/'
            className='footer__social-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='uil uil-linkedin-alt'></i>
          </a>
        </div>

        <span className='footer__copy'>
          Copyright &#169; 2022 KCS All Rights Reserved
        </span>
      </div>
    </footer>
  )
}

export default Footer
