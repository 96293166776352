import proshop from '../assets/proshop.png'
import thesaurus from '../assets/thesaurus.png'
import djevents from '../assets/djevents.png'
import vocabularyhour from '../assets/vocabularyhour.png'

export const projectsData = [
  {
    id: 1,
    image: vocabularyhour,
    title: 'Vocabulary Hour',
    category: 'web',
    link: 'https://vocabularyhour-kcs.netlify.app/',
    source: 'https://github.com/kcansari/vocabularyhour',
  },
  {
    id: 2,
    image: proshop,
    title: 'ProShop eCommerce Platform',
    category: 'web',
    link: 'https://proshopkcs.onrender.com/',
    source: 'https://github.com/kcansari/proshop',
  },
  {
    id: 3,
    image: thesaurus,
    title: 'Thesaurus App',
    category: 'app',
    link: 'https://thesauruskcs.netlify.app/',
    source: 'https://github.com/kcansari/thesaurus-app',
  },
  {
    id: 4,
    image: djevents,
    title: 'Dj Events',
    category: 'app',
    link: 'https://djeventskcs.netlify.app',
    source: 'https://github.com/kcansari/dj-events-frontend',
  },
]
